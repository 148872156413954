import React, { useState, useEffect } from 'react';
import './Slide.css';

const Slide = props => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % props.images.length);
    }, 10000); // Change image every 10 seconds

    return () => clearInterval(interval);
  }, [props.images.length]);

  const handleClick = () => {
    props.play([true, props.images[currentIndex]]);
  };

  return (
    <div className="slider-container" style={{ width: props.width, height: props.height }}>
      {props.images.map((image, index) => (
        <img
          key={index}
          src={image.alt === 'none' ? "https://img.youtube.com/vi/"+image.tag+"/maxresdefault.jpg" : "https://f005.backblazeb2.com/file/PhotosSite/Thumbnails/"+image.alt}
          alt={image.tag}
          onClick={handleClick}
          className={`slider-image ${index === currentIndex ? 'active' : ''}`}
        />
      ))}
    </div>
  );
};

export default Slide;