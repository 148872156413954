import React from "react";
import Programming from "./Programming";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import "./ProgrammingPage.css";
import Burger from "../../components/Burger/Burger";

const ProgrammingPage = () =>{
    const navigate = useNavigate();

    function backHome(){
        navigate("/");
    }

    return(
        <div className="prog_page">
            <title>Mirum Programming</title>
            <div className='header-box'>
                <img className="logo" src="Logo.png" alt="Mirum Productions" onClick={backHome} />
            </div>
            <Burger />
            <Programming />
            <Footer />
        </div>
    );
}

export default ProgrammingPage;