import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Burger.css';

const Burger = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };



  return (
    <div>
      <div className="burger" onClick={toggleMenu}>
        <img
          src="Burger.png"
          alt="Menu Icon"
          style={{ height: '100%', width: 'auto' }}
        />
      </div>
      <div className={`menu ${isOpen ? 'menu-open' : ''}`}>
        <ul>
          <li><div className='menu_items' onClick={() => {navigate("/")}}>Mirum Productions</div></li>
          <li><div className='menu_items' onClick={() => {navigate("/photos")}}>Mirum Photos</div></li>
          <li><div className='menu_items' onClick={() => {navigate("/programming")}}>Mirum Programming</div></li>
        </ul>
      </div>
    </div>
  );
};

export default Burger;