import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/Home/Home';
import Photos from './pages/Photos/Photos';
import ProgrammingPage from './pages/Prog/ProgrammingPage'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={ <Home /> } />
        <Route path="/programming" element={ <ProgrammingPage /> } />
        <Route path="/photos" element={ <Photos /> } />
      </Routes>
    </Router>
  );
}

export default App;
