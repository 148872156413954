import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Footer from '../../components/Footer/Footer';
import Slide from '../../components/Slide/Slide';
import LeftSection from '../../components/LeftSection/LeftSection';
import RightSection from '../../components/RightSection/RightSection';
import Info from '../../components/Info/Info';
import Burger from '../../components/Burger/Burger';
import { useNavigate } from 'react-router-dom';
import './Home.css';

const Home = () =>{
    const server = "https://jlmyall.net/joey";
    const [Slides, setSlides] = useState([{tag:"NpEaa2P7qZI", name:"LOADING", alt:"none"}]);
    const [Cats, setCats] = useState([])
    const [Play, setPlay] = useState([false,{tag:"NpEaa2P7qZI", name:"LOADING", alt:"none"}]);
    const navigate = useNavigate()

    useEffect(() => {
        async function fetchData() {
            const slide_res = await axios.get(server+"/slides");
            setSlides(slide_res.data);
            const cat_res = await axios.get(server+"/categories/random")
            setCats(cat_res.data);
        }
        fetchData()
    },[])

    function backHome(){
        navigate("/");
    }

    
    return (
        <div className='home_page'>
            <title>Mirum Productions</title>
            <div className="sides-gradient-overlay gradient-left" />
            <div className="sides-gradient-overlay gradient-right" />
            <div className='header-box'>
                <img className="logo" src="Logo.png" alt="Mirum Productions" onClick={backHome} />
            </div>
            <Burger />
            <div className='slide-box'>
                <Slide 
                    images={Slides} 
                    width="100vw" 
                    height="40vw"
                    play={setPlay}
                />
            </div>
            {
                Cats.map((item, index) => {
                    if (index % 2 === 0){return(<div key={index}><LeftSection play={setPlay} category={item.name} /><div className='split-info'></div></div>)}
                    else{return(<div key={index}><RightSection play={setPlay} category={item.name} /><div className='split-info'></div></div>)}
                })
            }
            <Info selection={Play[1]} trigger={Play[0]} back={setPlay} />
            <div className='return_to_top' onClick={() => {window.scrollTo(0,0);}}>Return to Top</div>
            <br/>
            <Footer />
        </div>
    );
}

export default Home;
